/* You can add global styles to this file, and also import other style files */
// :root {
//     --logo: url('/assets/images/logo/logo-light.png') !important;
//     --logo-reverse: url('/assets/images/logo/logo-dark.png') !important;
// }

@media print {
    body {
        background-image: initial !important;
        background-color: #ffffff !important;
        padding-top: 0px !important;
    }

    header { 
        display: none; 
    } 

    body.lp-opened-sidebar .lp-content {
        padding: 0px 0px 24px 0px !important;
    }

    abp-footer {
        display: none; 
    }

    .abp-toast {
        display: none !important;
    }
}